import React from 'react';
import GameListing from './GameListing.js';
import Page from './Page.js';
import GhostDriverLogo from "./images/headlights/logo.jpg";
import GhostDriverCover from "./images/headlights/cover.png";
import ArcsCover from "./images/arcs/cover.png";
import ArcsLogo from "./images/arcs/logo.svg";
import image from './images/products_small.jpg'


function Products() {
    return (
        <Page backgroundImage={image}>
            <div>
                <h1 data-aos="fade-down" style={{fontSize: "max(4vw, 40px)", paddingLeft: "32px"}}>Games</h1>
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                    <GameListing 
                        title="Arcs" 
                        subtitle="Original"
                        cover={ArcsCover} 
                        logo={ArcsLogo}
                        playUrl="arcs/game.htm"
                        description=
                        {
                            <>
                                An original game made in early 2023
                            </>
                        }
                     />
                    <GameListing 
                        title="Ghost Driver" 
                        subtitle="1st game released"
                        cover={GhostDriverCover} 
                        logo={GhostDriverLogo}
                        itchUrl={"https://icurety.itch.io/ghost-driver"}
                        playstoreUrl={"https://play.google.com/store/apps/details?id=com.Icurety.HeadlightsEvasion"}
                        appstoreUrl={"https://apps.apple.com/us/app/ghost-driver/id1660746052"}
                        description=
                        {
                            <>
                            A quick idea we had that we made into a game within a day.
                            <br/>
                            <br/>
                            Can you maneuver around as the oncoming traffic approaches?
                            <br/>
                            Do your best to not hit the oncoming traffic and obstacles, and see if you can get on top of the leaderboard!
                        </>
                        }
                     />
                     <p style={{fontFamily: "Georgia", fontSize: "20px", color: "#aaa", fontStyle: "italic", paddingLeft: "32px"}}>We are currently working on a bigger project that will be released in mid 2023.</p>
                </div>
            </div>
        </Page>
    )
}

export default Products;

