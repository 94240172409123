import React from 'react';
import TopBar from "./TopBar";


function Page(props) {
    return (
        <div>
            <div data-aos="fade-in" style={{zIndex: -1, position: "fixed", backgroundPosition: "center, center", backgroundImage: props.backgroundImage && "url('" + props.backgroundImage + "')", height: "100vh", width: "200vw", backgroundSize: "cover"}}></div>
            <TopBar />
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default Page;