import React from 'react';
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import itch from './images/itch.png';
import steam from './images/steam.png';
import appstore from './images/appstore.png';
import playstore from './images/googleplay.png';
import playLogo from './images/play.svg';

function GameListing(props) {
    return (
            <div data-aos="fade-right" style={{marginBottom: "64px", width: "100%", backgroundClip: "borer-box", overflow: "hidden", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundImage: "url('" + props.cover + "')", boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;"}}>
                
                <div style={{height: "100%", paddingBottom: "12px", backgroundColor: "rgba(32, 32, 32, 0.5)", backdropFilter: "blur(16px)",}}>
                    <div style={{display: "flex", backgroundColor: "rgba(10, 10, 10, 0.5)", alignItems: "center"}}>
                        <img src={props.logo} style={{margin: "32px", borderRadius: "128px", width: "128px", height: "128px"}}></img>
                        <div style={{margin: "16px", display: "inline-block"}}>
                            <h1 style={{marginBottom: "0px"}}>{props.title}</h1>
                            <div style={{color: "#999", fontSize: "20px"}}>{props.subtitle}</div>
                        </div>
                    </div>
                    <p style={{fontFamily: "Georgia", fontSize: "21px", marginLeft: "32px", marginRight: "20px", marginTop: "30px", marginBottom: "100px"}}>{props.description}</p>

                    <div style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                        {props.appstoreUrl && 
                            <StoreLink target="_blank" href={props.appstoreUrl}>
                                <StoreImage src={appstore} />
                            </StoreLink>
                        }

                        {props.playstoreUrl && 
                            <StoreLink target="_blank" href={props.playstoreUrl}>
                                <StoreImage src={playstore} />
                            </StoreLink>
                        }

                        {props.itchUrl && 
                            <StoreLink target="_blank" href={props.itchUrl}>
                                <StoreImage src={itch} />
                            </StoreLink>
                        }

                        {props.steamUrl && 
                            <StoreLink target="_blank" href={props.steamUrl}>
                                <StoreImage src={steam} />
                            </StoreLink>
                        }
                        {props.playUrl && 
                            <StoreLink target="_blank" href={props.playUrl} style={{borderRadius: "16px", border: "2px solid rgba(255, 255, 255, 0.1)", padding: "8px"}}>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <StoreImage src={playLogo} style={{height: "50px"}}/>
                                    <div style={{marginLeft: "16px", fontSize: "20px"}}>
                                        Play now

                                    </div>
                                </div>
                            </StoreLink>
                        }
                    </div>

                </div>
            </div>
    )
}

const StoreImage = styled.img`
    @media (min-width: 1000px) {
        max-width: 250px;
    }
    @media (max-width: 999px) {
        max-width: 150px;
    }

`;


const StoreLink = styled.a`
    margin-left: 20px;
    margin-bottom: 16px;
`;

const Hoverable = styled.div`
    &:hover {
        transform: scale(1.1, 1.1);
        transition: 500ms;
    }
    transition: 500ms;
`;

export default GameListing;