import React from 'react';
import TopBar from "./TopBar";
import image from './images/background.jpg'
import {Link} from 'react-router-dom';
import Page from "./Page";
import styled from 'styled-components';
import imageFlipped from './images/about_small_old.jpg'

function FrontPage() {

    return (
        <Page backgroundImage={imageFlipped}>
            <HeadDiv data-aos="fade-right" data-aos-id="super-duper" style={{display: "inline-block"}}>
                <HeadLine style={{fontSize: "max(3vw, 35px)", marginBottom: "2px"}} className="mainFont">
                    Welcome to Icurety Studios
                </HeadLine>
                <SubHeadLine style={{fontSize: "max(1vw, 20px)", fontWeight: "bold", color: "#999", marginTop: "4px", marginLeft: "16px"}}>
                    A software development company based in Denmark
                </SubHeadLine>
                
            </HeadDiv>

            <WorksLineContainer data-aos="fade-left" data-aos-id="super-duper" style={{display: "flex", justifyContent: "right", alignItems: "center"}}>
                <Link to="/products" style={{marginRight: "10vw", display: "inline-block", fontSize: "max(2vw, 30px)", marginBottom: "2px"}} className="mainFont">
                    See our works &#62;&#62;
                </Link>
            </WorksLineContainer>
        </Page>
    )
}

const HeadDiv = styled.div`

    margin-top: 15vh;

    @media only screen and (min-width: 768px)
    {
        margin-left: 8vw;
        margin-top: 50vh;
    }

`;

const HeadLine = styled.h1`

    text-align: center;

    @media only screen and(min-width: 768px)
    {
        text-align: left;
    }

`;

const SubHeadLine = styled.p`

    text-align: center;

    @media only screen and(min-width: 768px)
    {
        text-align: left;
    }

`;

const WorksLineContainer = styled.div`

    margin-top: 5vh;

    @media only screen and (min-width: 768px)
    {
        position: fixed; 
        right: 0;
    }

`;

export default FrontPage;