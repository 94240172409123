import React from 'react';
import { useState } from 'react'
import styled from "styled-components"
import { Link } from "react-router-dom";

function Divider() {
    return (
        <div style={{ display: "inline-block", padding: "0px", marginLeft: "32px", marginRight: "32px", height: "32px", width: "1px", backgroundColor: "#00abab" }}>
        </div>
    );
}

function Title(props) {

    let isViewingThatPage = window.location.pathname.endsWith(props.to);

    return (
        <Link className="titleText" to={props.to} style={{ textDecoration: "none", fontWeight: isViewingThatPage ? "bold" : "auto", transform: isViewingThatPage ? "scale(1.1, 1.1)" : "auto", color: "cyan", display: "inline-block", height: "100%" }}>
            <div>
                {props.children}
            </div>
        </Link>
    )
}

function TopBar() {


    const [showSidebar, setShowSidebar] = useState(false);

    const handleShowSideBar = () => {
        setShowSidebar(!showSidebar);
    }

    return (

        <Navbar>
            <Container>
                <div className="logo">
                    <Link to={"/"} style={{ paddingLeft: "16px", textDecoration: "none", display: "inline-block" }}>
                        <h2 className="icurety-header titleText">Icurety Studios<span className="blinking-class">_</span></h2>
                    </Link>
                </div>
                <Hamburger onClick={handleShowSideBar}>
                <CubeOutline style={showSidebar ? {transform: "rotateZ(45deg)"} : {}}>
                    <Cube style={showSidebar ? {transform: "rotateZ(45deg)", backgroundColor: "cyan"} : {backgroundColor: "white"}}></Cube>
                </CubeOutline>
                </Hamburger>
                <NavElements style={showSidebar ? {width: "270px"} : {}}>
                    <ul>
                        <li>
                            <Title to="/about">About</Title>
                        </li>
                        <li>
                            <Title to="/products">Products</Title>
                        </li>
                        <li>
                            <Title to="/contact">Contact</Title>
                        </li>
                    </ul>
                </NavElements>
            </Container>
        </Navbar>
    )
}

const CubeOutline = styled.div`
   
    padding: 6px;

    border: 4px solid white;
    transition: 0.3s ease-in;
`;

const Cube = styled.div`
    width: 12px;
    height: 12px;
    color: white;
    transition: 0.3s ease-in;
`;

const Hamburger = styled.div`
    @media(max-width: 767px) 
    {
        display: inline-block;
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
    }

    @media(min-width: 768px)
    {
        display: none;
    }

`;



const Navbar = styled.nav`

    @media (min-width: 768px)
    {
        height: 60px;
        position: relative;
    }
`;

const Container = styled.div`

    @media (min-width: 768px)
    {
        margin: 0 auto;
        padding: 0 15px;
        display: flex;
        
        align-items: center;
        height: 100%;
    }
`;

const NavElements = styled.div`

    z-index: 1000;

    @media(max-width: 767px)
    {
        position: absolute;
        right: 0;
        top: 60px;
        
        backdrop-filter: blur(80px);
        width: 0;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;

        .active {
            width: 270px;
        }

        ul {
            list-style: square;
            color: white;
            display: flex;
            flex-direction: column;
        }
        
        ul li {
            margin-right: unset;
            margin-top: 22px;
        }

        ul a.active::after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #574c4c;
        }
    }

    @media (min-width: 768px)
    {
        ul {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
        }
    
        ul li:not(:last-child) {
            margin-right: 60px;
        }

    }

`;


export default TopBar;