import React from 'react';
import Page from "./Page";
import image from './images/background_flip_horizontal.jpg'
import styled from "styled-components";

function Contact() {
    return (
        <Page backgroundImage={image}>
            <div style={{display: "flex", justifyContent: "right"}}>
                <Container data-aos="fade-left" data-aos-id="super-duper" style={{marginRight: "6vw", display: "inline-block"}}>
                    <Header style={{fontSize: "max(3vw, 30px)", marginBottom: "2px"}} className="mainFont">
                        Do you have questions for us?
                    </Header>
                    <Header style={{fontSize: "max(1vw, 20px)", fontWeight: "bold", color: "#999", marginTop: "4px", marginLeft: "16px"}}>
                        Feel free to send us an email at: <a href="mailto:contact@alphabyte.dk">zakiisak@gmail.com</a>
                    </Header>
                </Container>
            </div>
        </Page>
    )
}

const Container = styled.div`

    margin-top: 15vh;

    @media(min-width: 768px) 
    {
        margin-top: 50vh;
    }
`;

const Header = styled.h1`

    text-align: center;

    @media(min-width: 768px) 
    {
        text-align: center;
    }
`;

export default Contact;

