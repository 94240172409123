import React, { useRef, useState } from 'react';
import { CssBaseline, Grid, Tab, Tabs, ThemeProvider, createTheme, Avatar, Card, CardMedia, CardContent, Chip } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import profile from '../images/isak.jpg'
import styled from 'styled-components';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CarRentalIcon from '@mui/icons-material/CarRental';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import image from '../images/about_small.jpg'
import flames from './images/7flames.png'
import android_game from './images/android_game.png'
import ballpredator from './images/ballpredator.png'
import crazypong from './images/crazypong.png'
import javaquest from './images/javaquest.png'
import onskelisten from './images/onskelisten.png'
import rageplatformer from './images/rageplatformer.png'
import platformer from './images/platformer.png'
import icurety_logo from './images/icurety_logo.png'
import konkurser_logo from './images/konkurser_logo.png'
import mindj_logo from './images/mindj_logo.png'
import GitHubIcon from '@mui/icons-material/GitHub';
import AppleIcon from '@mui/icons-material/Apple';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CodeIcon from '@mui/icons-material/Code';
import PersonIcon from '@mui/icons-material/Person';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import GhostDriverCover from "../images/headlights/cover.png";

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function calculateAge() {
    let birth = new Date();

    return Math.floor((new Date().getTime() - 911689200000) / (1000 * 60 * 60 * 24 * 365));
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function Isak() {

    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = function (event, newValue) {
        setSelectedTab(newValue);
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div style={{ marginTop: "20px" }}
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <div>
                        {children}
                    </div>
                )}
            </div>
        );
    }

    function Project(props) {

        const addGithubUrl = function () {
            if (props.githubUrl) {
                return <div style={{ display: "flex", alignItems: "center" }}><a target="_blank" href={props.githubUrl}><GitHubIcon style={{ position: "relative", top: "4px", marginRight: "8px" }} />Se på Github</a></div>
            }
            else return <></>;
        }
        const addPlaystoreUrl = function () {
            if (props.playstoreUrl) {
                return <div><a target="_blank" href={props.playstoreUrl}><PlayArrowIcon style={{ position: "relative", top: "4px", marginRight: "8px" }} />Se på Google Playstore</a></div>
            }
            else return <></>;
        }
        const addAppstoreUrl = function () {
            if (props.appstoreUrl) {
                return <div ><a target="_blank" href={props.appstoreUrl}><AppleIcon style={{ position: "relative", top: "4px", marginRight: "8px" }} />Se på App Store</a></div>
            }
            else return <></>;
        }

        return (
            <ResponsiveCard style={{ marginTop: "32px", marginBottom: "32px", minHeight: "200px", }} variant="outlined">
                <CardMedia title="image" style={{ maxHeight: "200px" }} component="img" image={props.src} />
                <CardContent>
                    <Header>{props.title}</Header>
                    <Divider style={{ marginBottom: "16px" }}></Divider>
                    {props.children}
                    {addGithubUrl()}
                    {addPlaystoreUrl()}
                    {addAppstoreUrl()}
                </CardContent>
            </ResponsiveCard>
        )
    }

    const ResponsiveCard = styled(Card)`

        width: calc(100% - 16px);
        margin-left: 8px;

        @media (min-width: 768px) {
            margin-left: 32px;
            width: 25%;
        }
    `;

    function WorkCard(props) {
        return (
            <AdaptiveWorkCardSize {...props}>
                <Card style={{}} variant="outlined">
                    {props.src &&
                        <CardMedia title="image" style={{ padding: "16px", maxHeight: "200px", margin: "0" }} component="img" image={props.src} />
                    }
                    <CardContent>
                        <Header>{props.title}</Header>
                        <Divider style={{ marginBottom: "16px" }}></Divider>
                        {props.children}
                    </CardContent>
                </Card>
            </AdaptiveWorkCardSize>
        )
    }

    return (
        <ThemeProvider theme={darkTheme} >
            <div data-aos="fade-in" style={{ zIndex: -1, position: "fixed", backgroundPosition: "center, center", backgroundImage: "url('" + image + "')", height: "100vh", width: "100vw", top: 0, backgroundSize: "cover" }}></div>
            <CssBaseline />

            <Container>
                <Grid container>
                    <Grid item lg={3} style={{ marginBottom: "24px" }}>
                        <img src={profile} style={{ margin: "32px", marginBottom: "0px", width: "calc(100% - 64px)", height: "auto", borderRadius: "100%", objectFit: "cover" }}></img>
                        <Description>
                            <DescriptionField>Isak Østergaard</DescriptionField>
                            <DescriptionField>
                            Damhusvej 3, 5000 Odense
                            </DescriptionField>
                            <DescriptionField>{calculateAge()} år</DescriptionField>
                            <DescriptionField>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <EmailIcon style={{ marginRight: "20px" }} /> zakiisak@gmail.com
                                </div>
                            </DescriptionField>
                            <DescriptionField>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <PhoneIcon style={{ marginRight: "20px" }} /> 60 22 85 33
                                </div>
                            </DescriptionField>
                            <DescriptionField>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CarRentalIcon style={{ marginRight: "20px" }} /> Kørekort B
                                </div>
                            </DescriptionField>
                            <DescriptionField>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <a href="https://www.linkedin.com/in/isak-joshua-%C3%B8stergaard-654644143/"><LinkedInIcon style={{ marginRight: "20px" }} /> Se LinkedIn profil</a>
                                </div>
                            </DescriptionField>
                        </Description>

                    </Grid>
                    <Grid item lg={9}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Beskrivelse" />
                            <Tab label="Projekter" />
                            <Tab label="Timeline" />
                        </Tabs>
                        <TabPanel value={selectedTab} index={0} data-aos="fade-in">
                            <Header>
                                <PersonIcon style={{ marginRight: "8px" }} />Hvem jeg er
                            </Header>
                            <Divider />
                            <Text>
                                Hej! Mit navn er Isak, og jeg elsker at programmere!<br />
                                Lige siden jeg var ganske ung har det været én af mine største passioner.<br />
                                Selvom jeg ikke har de fleste ECTS point, har jeg arbejdet hårdt for at opnå læring på egen hånd. <br />
                                Jeg ser mig selv som en person med integritet, disciplin, loyalitet og villighed til at lære mere.
                                <br />
                                Du kan se nogle af mine projekter inde under fanen "Projekter".


                                <br />
                                <br />
                                I 2022 startede jeg og min bror vores egen virksomhed ved navn <span className='mainFont' style={{ color: "cyan" }}>Icurety</span>, hvor vi udvikler computer spil sammen. <br />Du kan se vores hjemmeside her:
                                <a style={{ paddingLeft: "8px" }} href="https://icurety.com">icurety.com</a>

                                <br />
                                <br />
                            </Text>

                            <Header>
                                <CodeIcon /> Programmerings kompetencer
                            </Header>
                            <Divider style={{ marginBottom: "8px" }} />
                            <div style={{marginLeft: "16px", display: "flex", gap: "10px", flexWrap: "wrap"}}>
                                <Chip style={{  }} label="Java" />
                                <Chip style={{  }} label="Spring" />
                                <Chip style={{  }} label="Javascript" />
                                <Chip style={{  }} label="React" />
                                <Chip style={{  }} label="C++" />
                                <Chip style={{  }} label=".NET" />
                                <Chip style={{  }} label="ASP" />
                                <Chip style={{  }} label="C#" />
                                <Chip style={{  }} label="Azure" />
                                <Chip style={{  }} label="App udvikling" />
                                <Chip style={{  }} label="Ionic" />
                                <Chip style={{  }} label="OpenGL" />
                                <Chip style={{  }} label="CSS - Web Design" />
                                <Chip style={{  }} label="BGFX" />
                            </div>

                            <Header style={{ marginTop: "32px" }}>
                                <PersonPinCircleIcon /> Mere om mit personlige liv
                            </Header>
                            <Divider />
                            <Text>
                                Derhjemme, finder man mig ofte på klaveret som jeg elsker at spille på. Har optrådt i mange forskellige kirker i Danmark og USA med anerkendte salmer og sange samt selvkomponeret musik. Jeg kommer fra en stor familie med 4 søskende og er derfor god til at indgå kompromisser og at samarbejde. Jeg er vild med at bage, så der er ofte hjemmebagte croissanter, jordbærtærter og eksotisk brød på bordet derhjemme.
                            </Text>
                        </TabPanel>
                        {/* Projects */}
                        <TabPanel value={selectedTab} index={1} data-aos="fade-in">
                            <Text>Her kan du se en liste af projekter jeg har lavet gennem de seneste år.</Text>
                            <Divider />

                            <div style={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
                                <Project src={onskelisten} playstoreUrl="https://play.google.com/store/apps/details?id=com.icurety.onskelisten" appstoreUrl="https://apps.apple.com/us/app/%C3%B8nsker/id1629921322" title="Ønskelisten">Ønskeliste app til android/ios </Project>
                                <Project src={GhostDriverCover} playstoreUrl="https://play.google.com/store/apps/details?id=com.Icurety.HeadlightsEvasion" appstoreUrl="https://apps.apple.com/us/app/ghost-driver/id1660746052" title="Ghost Driver">Et sjovt lille en dags projekt lavet i Unity</Project>
                                <Project src="https://github.com/zakiisak/2048/raw/master/screenshot_3.png?raw=true" title="2048" githubUrl="https://github.com/zakiisak/2048">2048 spillet lavet i Javascript</Project>
                                <Project src={javaquest} githubUrl="https://github.com/zakiisak/Java-Quest/" title="Java Quest">Lavet i Java i 2017</Project>
                                <Project src={flames} title="7 Flames">3D RPG spil lavet i Java med OpenGL</Project>
                                <Project src={ballpredator} title="Ball Predator">Horror game lavet i 2017 sammen med Malte Zielinski</Project>
                                <Project src={android_game} title="Click to Win">Android spil app lavet i LibGDX</Project>
                                <Project src={crazypong} title="Crazy Pong">Crazy version af pong lavet i C#</Project>
                                <Project src={platformer} title="Minecraft-like 2D Platformer">Hurtig 2d koncept af Minecraft lavet med LibGDX</Project>
                                <Project src={rageplatformer} title="Rage Platformer">En anden platformer lavet med LibGDX</Project>
                            </div>
                            <Text>Der kommer løbende flere projekter til denne liste.</Text>

                        </TabPanel>
                        <TabPanel value={selectedTab} index={2} data-aos="fade-in">
                            <Text>Her kan du se hvad jeg har beskæftiget mig med gennem årene</Text>
                            <Divider></Divider>
                            <Timeline>


                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        2022 - nu
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <WorkCard opposite src={icurety_logo} title="Spil udvikler">
                                            Arbejdet selvstændigt som spil udvikler, og vores første titel <i>King's Oath</i> bliver snart lanceret på <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Steam_icon_logo.svg/768px-Steam_icon_logo.svg.png" style={{ position: "relative", top: "4px", width: "24px", height: "24px" }} /> Steam
                                        </WorkCard>
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        <WorkCard src={mindj_logo} title="App udvikler">
                                            Videreudviklede en selfiebooth app på android, så den havde flere features.
                                        </WorkCard>

                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        2022 - 2023
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        2022 - nu
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>

                                        <WorkCard src={konkurser_logo} opposite title="Auktioner P/S">
                                            Ansat som Freelancer over et års tid, hvor jeg hjalp med at migrere databaser, fikse fejl på hjemmesiden, lave værktøjer og andre små ting.
                                        </WorkCard>
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        <WorkCard src="https://images.squarespace-cdn.com/content/v1/5cc6aee0fb22a567ea743a23/1565859522186-RI4844MNEHEL2B7TYTR9/TD-K+logo.png?format=700w" opposite title="TD-K">
                                            Ansat i et lille SCRUM team i TD-K, hvor vi arbejdede sammen på et faktureringssystem.
                                        </WorkCard>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        2021
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        2020 - 2021
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <WorkCard src="https://www.netto.dk/images/netto_logo-2019--no-circle.svg" title="Kasse assistent">
                                            Arbejdede i omtrent et halvt år i den lokale Netto
                                        </WorkCard>
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        <WorkCard src="https://yt3.googleusercontent.com/ytc/AL5GRJW0KwJANubZNLU8nAlbkgyppyLu8bp-djQAbPHFWDA=s900-c-k-c0x00ffffff-no-rj" opposite title="Frivillig">
                                            Var frivillig for Jesus Kristi Kirke af Sidste Dages Hellige i 2 år i New England området på østkysten i USA. Her var jeg med til at hjælpe hjemløse, tjene i kirken, og hjælpe dem omkring mig.
                                        </WorkCard>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        2018 - 2022
                                    </TimelineContent>
                                </TimelineItem>

                                <TimelineItem>
                                    <TimelineOppositeContent color="textSecondary">
                                        2017 - 2018
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <WorkCard src="https://www.zbc.dk/media/1360/zbc_neg.png" title="Datatekniker med speciale i programmering">
                                            Studerede til datatekniker med speciale i programmering på ZBC i Ringsted.
                                        </WorkCard>
                                    </TimelineContent>
                                </TimelineItem>





                            </Timeline>
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>

        </ThemeProvider>
    )


}

const AdaptiveWorkCardSize = styled.div`
    width: 100%;
    @media (min-width: 768px) {

        
    }
`;
/*
        width: 50%;
        position: relative;
left: ${props => props.opposite ? "50%" : "0"};

*/

const Divider = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 100%;
`;

const Header = styled.div`
    font-size: 32px;
    @media (max-width: 767px) {
        margin: 16px;
    }
`;

const Text = styled.p`
    font-size: 18px;
    font-family: Georgia;
    margin-right: 32px;

    @media (max-width: 767px) {
        margin: 16px;
    }
`;

const Container = styled.div`
    backdrop-filter: blur(80px);
    @media (min-width: 768px) {

        margin: 10vw;

        margin: 5vw 10vh;

        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        padding-top: 16px;

    }
    @media (max-width: 999px) {
    }
`;

const Description = styled.div`

    margin-left: 32px;
`;

const DescriptionField = styled.p`
    margin-top: 4px;
    margin-bottom: 4px;
`;
