import React from 'react';
function NotFound() {

    return (
        <div>
            <h1>The requested page was not found - 404</h1>
        </div>
    )
}

export default NotFound;