import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import NotFound from './NotFound';
import FrontPage from './FrontPage';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import About from './About';
import Products from './Products';
import Contact from './Contact';
import SubdomainController from './SubdomainController';
import Isak from './cv/isak';

Aos.init();

const router = createBrowserRouter([
  {
    path: "/s",
    element: <App />,
  },
  {
    path: "/",
    element: <FrontPage />,
  },
{
    path: "/isak-cv",
    element: <Isak />
},

  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/contact",
    element: <Contact />,
  }
]); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SubdomainController 
      subdomains={
        {
          "default": <RouterProvider router={router}></RouterProvider>,
          "isak": <Isak />
        }
      }
    />
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
