import React from 'react';
import Page from "./Page";
import image from './images/about_small.jpg'
import styled from 'styled-components';

function About() {
    return (
        <Page backgroundImage={image}>
            <div data-aos="fade-down">
                <h1 style={{textAlign: "center", marginTop: "15vh"}}>About Icurety</h1>
                <Text style={{textAlign: "center"}}>
                    Icurety was founded in 2022 and specializes in software development.
                </Text>
                <Text style={{textAlign: "center"}}>
                    At the moment, the company consists of the brothers Isak and Lukas Østergaard.
                    <br/>
                    <br/>
                    We have a passion for video games and we are currently focusing on indie game development.
                </Text>
                <Text style={{textAlign: "center"}}>
                    If you would like to hire us or have a question, please send us an email at icurety@gmail.com.
                </Text>
            </div>
        </Page>
    )
}

const HeadDiv = styled.div`

    margin-top: 15vh;

    @media only screen and (min-width: 768px)
    {
        margin-left: 8vw;
        margin-top: 50vh;
    }

`;

const Text = styled.p`

    margin: 16px;
`;

const Divider = styled.div`

`;

export default About;

