export default function SubdomainController(props) {

    let subdomain = window.location.host.toLowerCase().split('.')[0];

    if(props.subdomains[subdomain])
    {
        return props.subdomains[subdomain];
    }
    else return props.subdomains["default"];
}

/*
export default function SubdomainController() {

    const domainController = {
        subdomains: {},
        addDomain: function(subdomain, handler){
            this.subdomains[subdomain] = handler;
        },
        addDefaultDomain: function(handler){
            this.subdomains["default"] = handler;
        },
        render: function() {
            let subdomain = window.location.pathname.toLowerCase().split('.')[0];

            if(this.subdomains.has(subdomain))
            {
                this.subdomains[subdomain]();
            }
            else this.subdomains["default"]();
        }
    };
    return domainController;

}*/